<template>
  <div class="bonnitui">
    <img src="../../assets/product/bonnitui/1.png" class="headerimg" />

    <div class="website">
      <div class="title">你的网站 淹没在同行网站里？</div>

      <div class="websitelist">
        <div class="websitecard">
          <div class="websiteimg backimg">
            <div class="websitefont">搜不到</div>
            <div class="websiteEnfont">NOT FOUND</div>
          </div>
          <div class="websiteinfo">网站排名后客户搜不到</div>
        </div>

        <div class="websitecard">
          <div class="websiteimg backimg1">
            <div class="websitefont">没人看</div>
            <div class="websiteEnfont">NO ONE SAW IT</div>
          </div>
          <div class="websiteinfo">商城没人看订单数量少</div>
        </div>

        <div class="websitecard">
          <div class="websiteimg backimg2">
            <div class="websitefont">效果差</div>
            <div class="websiteEnfont">POOR EFFECT</div>
          </div>
          <div class="websiteinfo">宣传效果差客户咨询少</div>
        </div>

        <div class="websitecard">
          <div class="websiteimg backimg3">
            <div class="websitefont">不精准</div>
            <div class="websiteEnfont">IMPRECISE</div>
          </div>
          <div class="websiteinfo">客户不精准拉新成本高</div>
        </div>
      </div>
    </div>

    <div class="seooptimizing">
      <div class="title">网站SEO优化 让你少花60%的拓客时间和精力</div>
      <div class="title2">官网推广+产品推广+品牌推广</div>

      <div class="seocard">
        <div class="seoimg">
          <img src="../../assets/product/bonnitui/6.png" alt="" />
        </div>
        <div class="seolist">
          <div class="seoline">
            <div class="block">1</div>
            <div class="seo">
              <div class="seolinetitle">提升网站搜索排名</div>
              <div class="seolineinfo">
                在搜索关键词时，网站排名将大大提升，展示在百度等首页
              </div>
            </div>
          </div>
          <div class="seoline">
            <div class="block">2</div>
            <div class="seo">
              <div class="seolinetitle">提升企业形象/印象</div>
              <div class="seolineinfo">
                客户搜索相关品牌/产品信息网站将展现在搜索结果
              </div>
            </div>
          </div>
          <div class="seoline">
            <div class="block">3</div>
            <div class="seo">
              <div class="seolinetitle">增加/改善营销渠道</div>
              <div class="seolineinfo">
                网站将长期展示在搜索结果首页，能获得更多有效客源
              </div>
            </div>
          </div>
          <div class="seoline">
            <div class="block">4</div>
            <div class="seo">
              <div class="seolinetitle">更精准有效地获客</div>
              <div class="seolineinfo">
                客户搜索指定关键字，便能找到网站，客源更加精准
              </div>
            </div>
          </div>
          <div class="seoline">
            <div class="block">5</div>
            <div class="seo">
              <div class="seolinetitle">托管服务省钱省心</div>
              <div class="seolineinfo">
                购买后客户只需坐等优化，团队帮忙更新网站文章
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="change">
      <div class="title">起步比别人慢 但SEO让你跑得比别人快</div>
      <div class="title2">对一般客户而言，网站排名越前，企业越值得信赖</div>

      <div class="changlist">
        <div class="changecard changecardimg">
          <div class="changetitle">翻了几页都找不到</div>
          <div class="changein">在百度搜索结果翻了几页都找不到</div>
          <div class="changetext">
            <img src="../../assets/product/bonnitui/8.png" alt="" />
            <span>优化前</span>
          </div>
        </div>
        <div class="changimg">
          <img src="../../assets/product/bonnitui/9.png" alt="" />
        </div>
        <div class="changecard changecardimg2">
          <div class="changetitle">搜关键词精准引流</div>
          <div class="changein">搜关键词首页及找到答案</div>
          <div class="changetext">
            <img src="../../assets/product/bonnitui/11.png" alt="" />
            <span>优化后</span>
          </div>
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">行业案例</div>
      <div class="title2">8年间已服务10000+客户，续费率81%</div>

      <div class="caselist">
        <div class="casetag">
          <div class="tagcard ontagcard">
            <div class="tagtitle">工业制造</div>
            <div class="taglist">
              <div class="taginfo">
                <div class="tagtext">586</div>
                <div class="tagtext">案例</div>
              </div>
              <div class="taginfo ontagborder">
                <div class="tagtext">53%</div>
                <div class="tagtext">获客成本降低</div>
              </div>
              <div class="taginfo">
                <div class="tagtext">97%</div>
                <div class="tagtext">上次率</div>
              </div>
            </div>
          </div>
          <div class="tagcard">
            <div class="tagtitle">教育行业</div>
            <div class="taglist">
              <div class="taginfo">
                <div class="tagtext">436</div>
                <div class="tagtext">案例</div>
              </div>
              <div class="taginfo tagborder">
                <div class="tagtext">62%</div>
                <div class="tagtext">获客成本降低</div>
              </div>
              <div class="taginfo">
                <div class="tagtext">96%</div>
                <div class="tagtext">上次率</div>
              </div>
            </div>
          </div>
          <div class="tagcard">
            <div class="tagtitle">农牧渔业</div>
            <div class="taglist">
              <div class="taginfo">
                <div class="tagtext">485</div>
                <div class="tagtext">案例</div>
              </div>
              <div class="taginfo tagborder">
                <div class="tagtext">53%</div>
                <div class="tagtext">获客成本降低</div>
              </div>
              <div class="taginfo">
                <div class="tagtext">93%</div>
                <div class="tagtext">上次率</div>
              </div>
            </div>
          </div>
        </div>

        <div class="casecard">
          <div class="casecardtitle">贝恩机械</div>
          <div class="casecardinfo">
            客户围绕企业业务和产品关键词做推广。增加对企业曝光率，提升品牌知名度和网站流量，从而获取更多潜在客户。上首页30天内，客户网站日均流量由个位数上升到三位数，随着优化深入，流量呈现上升趋势。
          </div>
          <div class="beforeoptimization">
            <span class="betag">优化前</span>
            <span class="betext">排名：852</span>
            <span class="betext">百度收录数：2</span>
          </div>
          <div class="postoptimality">
            <span class="onbetag">优化后</span>
            <span class="betext">排名：1</span>
            <span class="betext">百度收录数：83</span>
            <span class="betext">日均流量：165 <span>↑</span></span>
          </div>
          <div class="caseimg">
            <img src="../../assets/product/bonnitui/13.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="simpleness">
      <div class="title">把复杂的交给我们 让SEO也如此简单</div>
      <div class="simplenesslist">
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/14.png" alt="" />
          <div class="simplenessinfo">咨询了解服务</div>
        </div>
        <div class="simplenessimg">
          <img src="../../assets/product/bonnitui/22.png" alt="" />
        </div>
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/15.png" alt="" />
          <div class="simplenessinfo">产品原型及需求文档</div>
        </div>
        <div class="simplenessimg">
          <img src="../../assets/product/bonnitui/22.png" alt="" />
        </div>
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/16.png" alt="" />
          <div class="simplenessinfo">产品原型及Axure文件</div>
        </div>
        <div class="simplenessimg">
          <img src="../../assets/product/bonnitui/22.png" alt="" />
        </div>
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/17.png" alt="" />
          <div class="simplenessinfo">UI设计效果图及Sketch源文件</div>
        </div>
      </div>

      <div class="simplenesslist">
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/18.png" alt="" />
          <div class="simplenessinfo">提供网址/关键字</div>
        </div>
        <div class="simplenessimg">
          <img src="../../assets/product/bonnitui/22.png" alt="" />
        </div>
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/19.png" alt="" />
          <div class="simplenessinfo">签订优化合同</div>
        </div>
        <div class="simplenessimg">
          <img src="../../assets/product/bonnitui/22.png" alt="" />
        </div>
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/20.png" alt="" />
          <div class="simplenessinfo">开始优化工作</div>
          <div class="info">90天之内</div>
        </div>
        <div class="simplenessimg">
          <img src="../../assets/product/bonnitui/22.png" alt="" />
        </div>
        <div class="simplenesscard">
          <img src="../../assets/product/bonnitui/21.png" alt="" />
          <div class="simplenessinfo">达标后再扣费</div>
        </div>
      </div>
    </div>

    <div class="information">
      <div class="title">收费透明 实时提供优化数据</div>

      <div class="informationlist">
        <div class="informationcard">
          <div class="informationimg">
            <img src="../../assets/product/bonnitui/23.png" alt="" />
          </div>
          <div class="informationtitle">专业技术团队</div>
          <div class="informationinfo">
            <div class="informationline">专注SEO领域十年</div>
            <div class="informationline">SEO优化经验丰富</div>
            <div class="informationline">精通百度算法</div>
            <div class="informationline">效果达标率超90%</div>
          </div>
        </div>

        <div class="informationcard">
          <div class="informationimg">
            <img src="../../assets/product/bonnitui/24.png" alt="" />
          </div>
          <div class="informationtitle">正规白帽手段</div>
          <div class="informationinfo">
            <div class="informationline">安全又可靠</div>
            <div class="informationline">关键词质量保障</div>
            <div class="informationline">更符合百度算法</div>
            <div class="informationline">长期稳定首页排名</div>
          </div>
        </div>

        <div class="informationcard">
          <div class="informationimg">
            <img src="../../assets/product/bonnitui/25.png" alt="" />
          </div>
          <div class="informationtitle">见效果才扣费</div>
          <div class="informationinfo">
            <div class="informationline">按天付费</div>
            <div class="informationline">账户付费清晰</div>
            <div class="informationline">不上首页不扣费</div>
            <div class="informationline">支持新建网站优化</div>
          </div>
        </div>

        <div class="informationcard">
          <div class="informationimg">
            <img src="../../assets/product/bonnitui/26.png" alt="" />
          </div>
          <div class="informationtitle">数据监控系统</div>
          <div class="informationinfo">
            <div class="informationline">数据监控系统</div>
            <div class="informationline">独家排名平台展示</div>
            <div class="informationline">一键查询达标情况</div>
            <div class="informationline">效果全程透明跟踪</div>
          </div>
        </div>
      </div>
    </div>

    <div class="taboo">
      <div class="taboolist">
        <div class="tabootitle">行业禁忌</div>
        <div class="tabooinfo">
          金融理财、 网赚、房地产、软件、娱乐游戏、私人侦探、商务调查、
          淘宝刷流量、追债讨债 针孔摄像头、 医疗保健、开锁、办证
          机票预定、住宅风水、
          夜场招聘、模特招聘、成人用品、色情、赌博、毒品、枪支弹药行业不能做SEO
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.taboo {
  margin-top: 60px;
  background: #f5f7fa;
  padding: 60px;
  .taboolist {
    margin: auto;
    width: 78%;
    .tabootitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
    .tabooinfo {
      font-size: 14px;
      font-weight: 400;
      color: #616b80;
      line-height: 24px;
      margin-top: 20px;
    }
  }
}

.information {
  margin-top: 140px;
  .informationlist {
    margin: auto;
    margin-top: 61px;
    display: flex;
    justify-content: space-between;
    width: 78%;
    .informationcard {
      width: 325px;
      height: 384px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      text-align: center;
      box-sizing: border-box;
      .informationimg {
        margin-top: 60px;
        img {
          width: 70px;
        }
      }
      .informationtitle {
        margin-top: 40px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }
      .informationinfo {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        .informationline {
          margin-top: 10px;
        }
      }
    }
  }
}

.simpleness {
  margin-top: 134px;
  padding: 90px;
  background: #f5f7fa;
  .simplenesslist {
    margin: auto;
    margin-top: 60px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .simplenessimg {
      width: 40px;
      img {
        width: 100%;
      }
    }
    .simplenesscard {
      width: 240px;
      height: 194px;
      box-shadow: 0px 2px 10px 0px rgba(174, 174, 174, 0.5);
      text-align: center;
      font-size: 14px;
      img {
        width: 50px;
        margin-top: 40px;
      }
      .simplenessinfo {
        margin-top: 24px;
      }
      .info {
        font-weight: 400;
        color: #6d7278;
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
}

.case {
  margin-top: 140px;
  .caselist {
    margin: auto;
    width: 50%;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .casecard {
      background-color: #f5f7fa;
      min-width: 944px;
      padding: 0px 35px;
      box-sizing: border-box;
      .caseimg {
        margin-top: 38px;
        img {
          width: 862px;
        }
      }
      .casecardtitle {
        margin-top: 90px;
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        text-align: center;
      }
      .casecardinfo {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        margin-top: 20px;
      }

      .postoptimality {
        margin-top: 20px;
        .onbetag {
          width: 80px;
          height: 34px;
          background: #2f7ffc;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          padding: 7px 19px;
        }
      }
      .betext {
        display: inline-block;
        min-width: 68px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-left: 20px;
        span {
          color: #2f7ffc;
        }
      }
      .beforeoptimization {
        margin-top: 40px;
        .betag {
          width: 80px;
          height: 34px;
          background: rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
          padding: 7px 19px;
        }
      }
    }
    .casetag {
      color: #fff;
      height: 1012px;
      min-width: 456px;
      background-image: url("../../assets/product/bonnitui/12.png");
      background-size: 100%;
      padding-top: 40px;
      box-sizing: border-box;
      .ontagcard {
        background-color: #f5f7fa;
        color: #000;
      }
      .tagcard {
        margin-top: 40px;
        padding-top: 0px 16px;
        .tagtitle {
          text-align: center;
          font-size: 36px;
          font-weight: 500;
          line-height: 50px;
          padding-top: 16px;
        }
        .taglist {
          text-align: center;
          margin: 0 38px;
          margin-top: 60px;
          padding-bottom: 16px;
          font-size: 24px;
          font-weight: 500;
          line-height: 33px;
          display: flex;
          .tagborder {
            margin-left: 32px;
            margin-right: 15px;
            padding: 0px 32px;
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            border-right: 1px solid rgba(255, 255, 255, 0.5);
          }
          .ontagborder {
            margin-left: 32px;
            margin-right: 15px;
            padding: 0px 32px;
            border-left: 1px solid rgba(0, 0, 0, 0.5);
            border-right: 1px solid rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

.change {
  margin-top: 134px;
  background-color: #f5f7fa;
  padding-top: 90px;
  padding-bottom: 78px;

  .changlist {
    margin: auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 46px;
    .changimg {
      img {
        width: 119px;
      }
    }
    .changecardimg {
      background-image: url("../../assets/product/bonnitui/7.png");
    }
    .changecardimg2 {
      background-image: url("../../assets/product/bonnitui/10.png");
    }
    .changecard {
      width: 595px;
      height: 383px;

      background-size: 100%;
      text-align: center;
      .changetitle {
        margin-top: 104px;
        font-size: 36px;
        font-weight: 500;
        line-height: 50px;
      }
      .changein {
        font-size: 24px;
        font-weight: 500;
        color: #616b80;
        line-height: 33px;
        margin-top: 20px;
      }
      .changetext {
        margin-top: 53px;
        img {
          width: 37px;
          vertical-align: middle;
        }
        span {
          vertical-align: middle;
          margin-left: 30px;
          font-size: 30px;
          font-weight: 500;
          line-height: 42px;
        }
      }
    }
  }
}

.seooptimizing {
  margin-top: 140px;
  .seocard {
    margin: auto;
    width: 80%;
    display: flex;
    .seoimg {
      img {
        width: 656px;
      }
    }
    .seolist {
      margin-left: 211px;
      margin-top: 70px;
      .seoline {
        margin-bottom: 40px;
        .seo {
          display: inline-block;
          .seolinetitle {
            font-size: 24px;
            font-weight: 500;
            line-height: 23px;
          }
          .seolineinfo {
            vertical-align: middle;
            font-size: 14px;
            font-weight: 500;
            color: #616b80;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.website {
  margin-top: 140px;
  .websitelist {
    width: 80%;
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .websitecard {
      width: 325px;
      height: 335px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      .backimg {
        background-image: url("../../assets/product/bonnitui/2.png");
      }
      .backimg1 {
        background-image: url("../../assets/product/bonnitui/3.png");
      }
      .backimg2 {
        background-image: url("../../assets/product/bonnitui/4.png");
      }
      .backimg3 {
        background-image: url("../../assets/product/bonnitui/5.png");
      }
      .websiteimg {
        width: 100%;
        height: 251px;
        background-size: 100%;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        .websitefont {
          padding-top: 70px;
          font-size: 46px;
          margin-bottom: 16px;
        }
        .websiteEnfont {
          font-size: 24px;
        }
      }
      .websiteinfo {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #616b80;
        line-height: 33px;
        margin-top: 26px;
      }
    }
  }
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}

.title2 {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #616b80;
  line-height: 33px;
  margin-top: 20px;
}

.block {
  width: 44px;
  height: 44px;
  background: #2f7ffc;
  display: inline-block;
  margin-right: 24px;
  line-height: 10px;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 17px 6px;
  vertical-align: top;
  text-align: center;
}
</style>
